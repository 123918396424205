<template>
  <div>
    <banner bg-name="banner-duty.png" desc-type="banner-descTwo.png"></banner>
    <div class="duty-partOne">
      <div class="partOne-intro">
        <div class="line"></div>
        <p class="intro-word">全球战疫 一起行动</p>
        <p class="intro-content">在新冠肺炎疫情之战中，成都众望智慧科技有限公司秉持科技向善，以互联网科技的力量，通过我们的技术和产品为抗击疫情提供支持。</p>
      </div>
      <img
        style="width: 858px; height: 745px; margin-top: 103px"
        src="@/assets/images/duty-intro.png"
        alt
      />
    </div>
    <div class="duty-partTwo">
      <div style="width: 1200px; margin: 0 auto; display: flex">
        <img
          style="width: 726px; height: 560px; margin-top: 145px"
          src="@/assets/images/duty-info.png"
          alt
        />
        <div class="partTwo-protect">
          <div class="line"></div>
          <p class="protect-title">大智慧环保</p>
          <p
            class="protect-content"
          >大智慧环保分会隶属于四川省环境保护产业协会，成立于2020年6月30日。重点加强感知层智能环保设备研发和智慧层拓展数据运用等方面的建设，在相关细分领域深耕拓展。</p>
        </div>
      </div>
    </div>
    <div class="duty-partThree">
      <div style="width: 1200px; margin: 0 auto; overflow: hidden">
        <p class="partThree-title">众望智慧强大的研发能力得到越来越多企业的信任</p>
        <div class="partThree-content">
          <div class="content-item">
            <p class="item-title">1000+</p>
            <p class="item-content">用户选择</p>
          </div>
          <ul class="content-item">
            <p class="item-title">
              <span>124</span>
              <span style="font-size: 32px">家</span>
            </p>
            <p class="item-content">合作伙伴</p>
          </ul>
          <div class="content-item">
            <p class="item-title">
              <span>7</span>
              <span style="font-size: 28px">x12小时</span>
            </p>
            <p class="item-content">售后服务</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner';
export default {
  components: {
    Banner
  },
  mounted() {
    this.$nextTick(() => {
      this.rouId = +this.$route.query.params;

      console.log(this.$route.query.params);
      this.handleJumpToDetail(this.rouId);
    });
  },
  methods: {
    // 跳往细节
    handleJumpToDetail(index) {
      switch (index) {
        case 0:
          document.querySelector('.duty-partOne').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        case 1:
          document.querySelector('.duty-partTwo').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        default:
          break;
      }
    }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal.query.params !== oldVal.query.params) {
            this.rouId = +newVal.query.params;
            console.log(this.rouId);
            this.handleJumpToDetail(this.rouId);
          }
          return newVal;
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.duty-partOne {
  height: 962px;
  width: 1200px;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  .partOne-intro {
    width: 332px;
    height: 236px;
    margin: 320px 11px 0 20px;
    .line {
      width: 43px;
      height: 0px;
      border: 2px solid #1d7df8;
      margin-left: 1px;
      margin-bottom: 17px;
    }
    .intro-word {
      font-size: 24px;
      margin-bottom: 61px;
      color: #333333;
      font-weight: bold;
    }
    .intro-content {
      font-size: 18px;
      color: #333333;
      font-weight: 400;
    }
  }
}
.duty-partTwo {
  height: 830px;
  background: url('./../../../assets/images/duty-partTwo.png') no-repeat;
  background-size: 100% 100%;
  .partTwo-protect {
    width: 332px;
    height: 236px;
    margin-top: 265px;
    margin-left: 110px;
    .line {
      width: 43px;
      height: 0px;
      margin-left: 1px;
      margin-bottom: 17px;
      border: 2px solid #1d7df8;
    }
    .protect-title {
      margin-bottom: 60px;
      font-size: 24px;
      color: #ffffff;
      font-weight: bold;
    }
    .protect-content {
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
    }
  }
}
.duty-partThree {
  height: 549px;
  background: url('./../../../assets/images/duty-partThree.png') no-repeat;
  background-size: 100% 100%;
  .partThree-title {
    margin: 118px 0 108px 213px;
    font-size: 32px;
    color: #ffffff;
    font-weight: 400;
  }
  .partThree-content {
    width: 1005px;
    height: 124px;
    display: flex;
    margin-left: 70px;
    justify-content: space-between;
    .content-item {
      display: flex;
      flex-direction: column;

      .item-title {
        font-size: 60px;
        font-weight: bold;
        color: #ffffff;
      }
      .item-content {
        margin-top: 8px;
        font-size: 28px;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
}
</style>
